body {
  margin: 0;
  font-family: Lato, sans-serif;
  /*
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
}

input:-webkit-autofill::first-line {
  font-size: 15px;
  font-family: Lato, sans-serif;
}

input.MuiInput-input {
  padding-left: 5px;
}

::-webkit-scrollbar {
  width: 15px;
  background: transparent;
  height: 6px; /* height of horizontal scrollbar ← You're missing this */
  width: 6px;
  border-radius: 0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background: transparent;
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #c6c9e0;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #c6c9e0 transparent;
}
